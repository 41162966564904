import { useSearchParams } from "react-router-dom";
import "./black-friday.css";
import { AppBarGeneric } from "../components/app_bar_generic";
import { useTranslation } from "react-i18next";
import logoBF24 from "../assets/images/black-friday/logo_bf24_white.svg";
import fechaBF24 from "../assets/images/black-friday/fecha_bf24.svg";
import banderaBF24 from "../assets/images/black-friday/Bandera-bf24-PT.svg";
import descuentoBF24 from "../assets/images/black-friday/descuento-bf24-PT.svg";

export const BlackFriday = () => {
  const [searchParams] = useSearchParams();
  let accessToken = searchParams.get("token");
  if (!accessToken) {
    accessToken = "";
  }

  const { t } = useTranslation();

  function onBackClick() {
    window.navigation.postMessage("native,,,pop,");
  }

  function navigatePlayStore() {
    window.navigation.postMessage(
      "web,https://play.google.com/store/apps/details?id=com.petroprix.petroprixapp&hl=es&gl=ES,,,"
    );
  }

  function navigateAppleStore() {
    window.navigation.postMessage(
      "web,https://apps.apple.com/es/app/petroprix/id864051888,,,"
    );
  }

  return (
    <>
      <div className="appbar-black-finde-parent">
        <div className="appbar-black-finde">
          <AppBarGeneric
            title={t("blackfinde.header")}
            backgroundColor="#1f1f1f"
            textColor="#ffffff"
            iconColor="#ffffff"
            onBackClick={onBackClick}
          ></AppBarGeneric>
        </div>
      </div>
      <div className="bf-background">
        <div className="bf-content">
          <div className="bf-header-finde">
            {/* <div className="bf-title-text black">
              {t("blackfinde.hero-title-1")}
              <span className="bf-title-marked black">
                {t("blackfinde.hero-title-red")}
              </span>
              {t("blackfinde.hero-title-2")}
            </div>
            <div className="bf-subtitle-text bold">
              {t("blackfinde.hero-subtitle")}
            </div> */}
            <img
              src={banderaBF24}
              className="bf-flag"
              alt="29, 30 de noviembre y 1 de diciembre"
            />
            <img src={logoBF24} className="bf-logo" alt="Black Finde logo" />
            <img
              src={descuentoBF24}
              className="bf-discount"
              alt="5 cts/litro descuento"
            />
            <div className="bf-button-header">
              <span className="bf-button-header-text bold">
                {t("blackfinde.hero-button")}
              </span>
              <p className="bf-button-header-subtitle">
                {t("blackfinde.hero-button-subtitle")}
              </p>
            </div>
          </div>

          <div className="bf-promo-info">
            <div className="bf-promo-info-title black">
              <span className="bf-promo-info-title-first-line">
                {t("blackfinde.info-title-1")}
              </span>
              <span className="bf-promo-info-title-second-line">
                <span className="bf-promo-info-title-underlined">
                  {t("blackfinde.info-title-red")}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 500 150"
                    preserveAspectRatio="none"
                    className="bf-promo-info-title-underline-svg"
                  >
                    <path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path>
                  </svg>
                </span>
                {t("blackfinde.info-title-2")}
              </span>
            </div>
          </div>

          <div className="bf-promo-info-text">
            <div className="bf-info-description">
              <p>
                {t("blackfinde.description-1")}
                <span className="bold">{t("blackfinde.description-2")}</span>
              </p>

              <p>
                {t("blackfinde.description-3")}
                <span className="bold">{t("blackfinde.description-4")}</span>
                {t("blackfinde.description-5")}
                <span className="bold">{t("blackfinde.description-6")}</span>
              </p>
            </div>
            <div className="bf-info-card">
              <div className="bf-info-card-title">
                {t("blackfinde.card-dates-title")}
              </div>
              <div className="bf-info-card-dates">
                <img
                  alt={t("blackfinde.card-dates-img-alt")}
                  src={fechaBF24}
                  className="bf-info-card-dates-img"
                />
              </div>
              <div className="bf-info-card-year black">
                {t("blackfinde.card-dates-year")}
              </div>
            </div>
            <div className="bf-info-description">
              <h3 className="bf-info-description-title bold">
                {t("blackfinde.description-info-steps-h3")}
              </h3>

              <p>
                <span className="bold">
                  {t("blackfinde.description-info-steps-p-bold")}
                </span>
                {t("blackfinde.description-info-steps-p")}
              </p>
            </div>
            {/* CARD 1 */}
            <div className="bf-info-card">
              <div className="bf-step-number">.1</div>
              <div className="bf-info-card-title-header bold">
                {t("blackfinde.card-1-title")}
              </div>
              <div className="bf-info-card-description">
                {t("blackfinde.card-1-description-1")}
                <span
                  className="bf-info-card-description-link bold"
                  onClick={navigatePlayStore}
                >
                  {t("blackfinde.card-1-description-2")}
                </span>{" "}
                {t("blackfinde.card-1-description-3")}
                <span
                  className="bf-info-card-description-link bold"
                  onClick={navigateAppleStore}
                >
                  {t("blackfinde.card-1-description-4")}
                </span>{" "}
                {t("blackfinde.card-1-description-5")}
              </div>
            </div>

            {/* CARD 2 */}
            <div className="bf-info-card">
              <div className="bf-step-number">.2</div>
              <div className="bf-info-card-title-header bold">
                {t("blackfinde.card-2-title")}
              </div>
              <div className="bf-info-card-description">
                {t("blackfinde.card-2-description")}
              </div>
            </div>

            {/* CARD 3 */}
            <div className="bf-info-card">
              <div className="bf-step-number">.3</div>
              <div className="bf-info-card-title-header bold">
                {t("blackfinde.card-3-title")}
              </div>
              <div className="bf-info-card-description">
                {t("blackfinde.card-3-description")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
